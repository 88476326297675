import React from "react"
import styled from "styled-components"
import { pSBC, linearGradient } from "../util/color"
import classNames from "classnames"

interface TitleProps {
    highlightColor?: string
    maxWidth?: number,
    classNname?: string,
    fontSize: string | number,
    children: string | JSX.Element[],
}

const Title = ({ maxWidth = 600, highlightColor = "#FF0000", fontSize, children, className }: TitleProps) => {
    const classes = classNames("title", "lh-solid")
    return (
        <h1 className={classes + ' ' + className} style={{
            maxWidth: maxWidth,
            fontSize: fontSize,
            "--highlight-color": linearGradient(highlightColor),
        }}>
            {children}
        </h1>
    )
}

export const Highlight = styled.mark`
    --hightlight-color: #ff0000
`
export default Title
