import React from "react"

export const SITE_MAX_WIDTH = 960

export interface CenterProps {
    className?: string,
    style?: React.CSSProperties,
    children: JSX.Element[] | JSX.Element | string,
}

const Center = (props: CenterProps) => {
    const defaultClasses = "ml-auto mr-auto relative flex flex-column"
    const defaultStyle = {
        maxWidth: SITE_MAX_WIDTH,
    }
    const classes = (props.className) ? defaultClasses + " " + props.className : defaultClasses
    return (
        <div className={classes} style={{ ...defaultStyle, ...props.style }}>
            {props.children}
        </div>
    )
}

export default Center
